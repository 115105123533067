const NOTIFICATION_TIMEOUT = 15000;

const UNSUPPORTED_COLUMNS = [
    "board-relation", "lookup", "dependency", "formula","item_id","creation_log","progress","last_updated"
];

const COLUMN_TYPE_NAME_MISMATCHES = {
    "color":"status",
    "multiple-person":"people",
    "numeric":"numbers",
    "timerange":"timeline",
    "long-text":"long_text",
    "autonumber":"auto_number",
    "timezone":"world_clock",
    "pulse-id":"item_id",
    "pulse-log":"creation_log",
    "pulse-updated":"last_updated",
    "columns-battery":"progress",
    "duration":"time_tracking",
    "color-picker":"color_picker",
    "tag":"tags",
    "boolean":"checkbox",
    "votes":"vote"
};

const isSupportedColumn = (type) => {
    return !UNSUPPORTED_COLUMNS.includes(type);
}

export {
    NOTIFICATION_TIMEOUT,
    isSupportedColumn,
    COLUMN_TYPE_NAME_MISMATCHES
};